import React from "react";
import DefaultLayout from "../layouts/default";
import FluidImage from "../components/FluidImage.js";
import Breadcrumbs from "../components/Custom/Navigation/Breadcrumbs";
import { Link } from "gatsby";

export default ({ location }) => {
	//  const dispatch = useDispatch();

	//   React.useEffect(() => {

	//   });

	return (
		<DefaultLayout location={location}>
			
            <Breadcrumbs location={location} />

			{/* on this page */}
            <div className="container is-fullwidth">
				<section className="section section-mini">
					<p className="is-normal">
						On this page:{" "}
						<a href="#1" className="">
							How to
						</a>
						,{" "}
						<a href="#2" className="">
							Submit
						</a>
						,{" "}
						<a href="#3" className="">
							Next steps
						</a>
					</p>
				</section>
			</div>

			{/* how to */}
            <a id="1" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>Submit a project.</h1>
							<p>
								&mdash; Okay here we are now. The moment of truth. You got a project and wanna build your rocket together with us. Let’s do it.
							</p>
							<h4 className="mt-50">Requirements and information needs.</h4>
							<p className="is-normal mt-25">
								When preparing your project for submission with us, please make sure to include any data we might need to evaluate your project. Like:
							</p>
							<p className="is-normal mt-25"><span className="is-primary tag">You</span> Tell us a about you. Who are you, what’s your profession and why did you come up with your idea/project.</p>
							<p className="is-normal mt-25"><span className="is-primary tag">Project</span> Describe your idea in some short sentences. We don’t need pages of information just make sure we get what you wanna do.</p>
							<p className="is-normal mt-25"><span className="is-primary tag">Plan</span> Where do you see yourself in the project, are you going to be the lead or do you wanna lean back and have us take care of everything?</p>
							<p className="is-normal mt-25"><span className="is-primary tag">Status</span> Is the idea only in your head, did you start working on your project or maybe you already got some first customers by now.</p>
							<p className="is-normal mt-25"><span className="is-primary tag">More</span> Let us know everything else you think is important (partners, other shareholders, your network).</p>
							<p className="mt-50">
								Everything is set and good to go? Submit!
							</p>
						</div>
					</div>
				</section>
			</div>

			{/* submit */}
            <a id="2" className="scroll-anchor"></a>
			<div className="hero hero-cta is-primary mt-50">
				<div className="hero-body">
					<div className="container is-layout">
						<section className="section">
							<div className="columns is-tablet is-centered">
								<div className="column is-8">
									<h1>Project submit zone!</h1>
									<p className="mb-50">Please submit your project via email:</p>
									<a
										href="mailto:hi@ntwk.ventures?subject=Project submission&body=Please provide all the information needed to help us screen and evaluate your project. Thank you!"
										className="button is-primary is-inverted is-letter-spacing is-uppercase mb-50"
									>
										Submit your project
									</a>
									<p>We are excited to hear from you!</p>
								</div>
							</div>
						</section>
					</div>
				</div>
			</div>

			{/* next */}
            <a id="3" className="scroll-anchor"></a>
			<div className="container is-layout">
				<section className="section">
					<div className="columns is-tablet is-centered">
						<div className="column is-8">
							<h1>After submitting, this is what's gonna happen.</h1>
						</div>
					</div>
                    
                    <div className="columns is-tablet mt-25">
						<div className="column">
							<h4>Screening your project.</h4>
							<ul className="nv-list is-normal">
								<li>Your project will be screened by the board and some of our cooperative experts.</li>
								<li>We check what you need and how we can be of help for you.</li>
								<li>Next up is a meeting in person where we talk details and make a plan for our joint venture.</li>
							</ul>
						</div>
						<div className="column">
							<h4>Additional Information.</h4>
							<p className="is-normal">
								You can be 100% sure that your idea is in great hands with us. No matter if we're gonna work together or not we would never do anything to harm you. If we start your project together we will do everything in our power to make it a success. If you or we decide that it’s not a perfect fit, we wish you all the best for your project and can still be of help if you need something.
							</p>
						</div>
					</div>
				</section>
			</div>

		</DefaultLayout>
	);
};
